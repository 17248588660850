import './App.css';
import EmailSearch from './emailSearch'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <EmailSearch />
      </header>
    </div>
  );
}

export default App;
