import React from 'react';
import { Row, Col, Typography, Badge } from 'antd'
import { CaretRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';

const CardLink = ({ item, setSelectCard, index, selectCard }) => {
    if (selectCard && selectCard !== index + 1) {
        return null
    } else if (selectCard && selectCard === index + 1) {
        return <Row style={{ width: '100%', marginTop: '-1vh' }} justify={'center'}>
            <Col span={24} style={{ textAlign: 'start', marginBottom: '10px' }}>
                <Typography.Paragraph style={{ color: 'white', fontWeight: 700, fontSize: '24px', marginBottom: 0 }} onClick={() => { setSelectCard(null) }}>
                    <ArrowLeftOutlined style={{ fontSize: '24px' }} /> ย้อนกลับ
                </Typography.Paragraph>
            </Col>
            <Col span={24} className='tableCol' style={{ background: 'white', maxWidth: '500px', borderRadius: '8px',justifySelf:'center' }}>
                <div style={{ width: '100%' }}
                    dangerouslySetInnerHTML={{ __html: `${item.html?.replaceAll(/[\x00-\x1F\x7F]/g, '')}` }}
                /></Col>
        </Row>
    }

    return <Badge.Ribbon text={item?.intime ? "พร้อมใช้งาน" : "หมดอายุ"} style={{marginLeft:'6px'}} color={item?.intime ? "green" : "red"} >
        <div class="timer-body" style={{ maxHeight: '70px', overflow: 'hidden', width: '-webkit-fill-available',marginBottom:'10px'}} onClick={() => { setSelectCard(index + 1) }}>
            <Row>
                <Col xs={3} md={3} style={{ textAlign: 'center', justifyContent: 'center', height: '100%' }}>
                    <img src="https://assets.nflxext.com/us/email/gem/nflx.png" alt="Netflix" width="16" style={{ padding: '8px 15px 0px 0px' }} />
                </Col>
                <Col xs={18} md={19}>
                    <Typography.Paragraph style={{ textAlign: 'start', marginBottom: 0, fontWeight: 700 }}>{item?.date} น.</Typography.Paragraph>
                    <Typography.Paragraph style={{ textAlign: 'start', fontWeight: 700, color: '#515151', marginBottom: 0 }} ellipsis>{item?.subject}</Typography.Paragraph>
                </Col>
                <Col xs={2} md={2}>
                    <CaretRightOutlined style={{ paddingTop: '22px' }} />
                </Col>
            </Row>
        </div>
    </Badge.Ribbon>
};

export default CardLink