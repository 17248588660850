import React, { useState } from 'react';
import _ from 'lodash'
import { message, Spin, Modal, Row, Col, Image,Empty } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import CardLink from './component/cardLink';
import Countdown, { zeroPad } from 'react-countdown-now';
import moment from 'moment'

const EmailSearch = () => {
  const [emailArray, setEmailArray] = useState([])
  const [keyword, setKeyword] = useState('')
  const [searching, setSearching] = useState(false)
  const [isCooldown, setIsCooldown] = useState(false);
  const [countdown, setCountDown] = useState(Date.now() + 15000);
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(15);
  const [selectCard, setSelectCard] = useState();
  
  const searchMail = async () => {
    if (!keyword) {
      message.error('กรุณากรอกอีเมล')
      return
    }
    setSearching(true)
    const uncodeEmail = await encodeURIComponent(keyword)
    const mail = await axios.get(`https://app-wfudlrftlq-uc.a.run.app/emails?senderEmail=${uncodeEmail}`).then(res => res.data).catch((err) => err)
    // const mail = await axios.get(`http://localhost:5000/emails?senderEmail=${uncodeEmail}`).then(res => res.data).catch((err) => err)
    if (mail?.response?.data?.error) {
      const errorText = mail?.response?.data?.error
      if (errorText === 'Invalid sender email format.') {
        message.error('รูปแบบอีเมลไม่ถูกต้องกรุณาตรวจสอบอีเมลที่ท่านกรอก')
      } else if (errorText === 'No valid emails found from this sender.' || errorText === 'No emails found from this sender.') {
        message.error(`ไม่มีอีเมลที่ได้รับจาก ${keyword}`)
      } else {
        message.error('ระบบผิดพลาดกรุณาลองใหม่อีกครั้ง')
      }
      setEmailArray([])
      setSearching(false)
      return
    }
    const loopEditData = await Promise.all(mail?.map((item) => {
      let html, intime = true
      if (item.html) {
        const indexTable = item.html.indexOf('<table')
        if (indexTable !== -1) {
          html = item.html.slice(indexTable);
        }
      }
      if (!html) {
        return null
      }
      if (item.date) {
        const add15min = moment(item.date, 'DD/MM/YYYY HH:mm').add(15, 'minute').add(7, 'hour')
        const isBefore15min = moment(add15min).isAfter(moment(Date.now()))
        intime = isBefore15min
      }
      return {
        ...item,
        html: html,
        intime: intime,
        date: moment(item.date, 'DD/MM/YYYY HH:mm').add(7, 'hour').format('DD/MM/YYYY HH:mm')
      }
    }))
    setEmailArray(_.orderBy(_.compact(loopEditData), ['date'], ['desc']))
    setSearching(false)
  }
  const onChangeInput = (e) => {
    setKeyword(e.target.value)
  }
  const handleClick = () => {
    if (isCooldown) return;  // Do nothing if button is in cooldown state

    // Start the cooldown process
    setIsCooldown(true);

    // Countdown logic: decrease the timer every second
    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          console.log(timer);
          clearInterval(countdown);  // Clear the interval once the timer reaches 0
          setIsCooldown(false);  // Re-enable the button after 15 seconds
        }
        return prev - 1;
      });
    }, 1000);  // Decrease every second
  };
  return <>
    <div className="main">
      {selectCard ? null :
        <nav>
          <span>
            <img width={100} src="https://img.rdcw.co.th/images/72e07a0ede77c82ee26addc2720f81aba8a1f093a3f2eca4cc3c2cc392ab0525.png" alt="Netflix" />
          </span>
          <button className="btn red-btn" onClick={() => { setOpen(true) }} style={{ background: 'none', border: '1px solid rgb(21, 42, 74)' }}>
            วิธีใช้งาน
          </button>
        </nav>
      }
      <div className="hero" style={{ marginBottom: '30px', marginTop:'45px', minHeight: '72vh' }}>
        {selectCard ? null :
          <>
            <span>Tomoru Netflix OTP</span>
            <span>กรุณากรอกอีเมล Netflix ที่ลูกค้าซื้อมา</span>
            <div className="hero-buttons" justify={'space-between'}>
              <input onChange={(e) => { onChangeInput(e) }} style={{borderRadius:'12px',fontSize:'20px', background: 'none', maxWidth: '437px' }} type="text" placeholder="Email Address" />
              <br />
              {isCooldown ?
                <button style={{ background: 'gray' }} disabled className="btn red-btn">
                  <Countdown date={countdown} key={countdown} renderer={({ seconds }) => {
                    // debugger
                    return <span><Spin spinning indicator={<LoadingOutlined style={{ color: 'white' }} />} /> ลองใหม่ใน {zeroPad(seconds)} วินาที</span>
                  }} />
                </button>
                : <button onClick={() => { searchMail(); handleClick(); setCountDown(Date.now() + 15000); setTimer(15) }} className="btn red-btn">
                  ค้นหาอีเมล
                </button>}
            </div>
            <span style={{ fontSize: '12px' }}>** ลิงค์ มีอายุ 15 นาที ^_^</span>
          </>
        }
        <Row style={{width:'100%'}}>
          <Col span={24}>
        {searching ? <Spin spinning style={{ marginTop: '12vh' }} indicator={<LoadingOutlined style={{ fontSize: '50px' }} />} />
          :
          _.isEmpty(emailArray) ?
          <Empty style={{marginTop:'60px'}} description={false}> <p>ไม่พบอีเมล</p></Empty>
           
            :
            emailArray?.map((item, index) => {

              return <CardLink item={item} setSelectCard={setSelectCard} index={index} selectCard={selectCard} />
            })

        }
        </Col>
        </Row>
      </div>
      <div style={{ width: '100%', textAlign: 'center', marginBottom: '0px',position:'fixed' }}>
       <strong><p style={{ color: '#878787',fontSize:'10px' }}>©Tomoru Official 2024</p></strong> 
      </div>
    </div>
    <Modal title="วิธีการใช้งาน" open={open} onCancel={() => { setOpen(false) }} footer={false}>
      <Row>
        <Col span={24}>
          <Image style={{ width: '100%' }} src={'/howto.jpg'} alt={'how to '} />
        </Col>
      </Row>
      <button onClick={() => { setOpen(false) }} className="btn" style={{ background: 'rgb(229, 9, 20)',height:'50px',fontSize:'20px', width: '100%', marginTop: '16px' }}>
        เข้าใจแล้ว
      </button>
    </Modal>

  </>
};

export default EmailSearch